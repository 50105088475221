import { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import useStorage from 'src/sdk/hooks/useStorage'
import { formatterPrice } from 'src/utils/formatterPrice'

import { useAccountContext } from '../../account/context'

const CashbackBanner = () => {
  const { isLoggedIn, cashBackBalance } = useAccountContext()

  const { storageGetItem } = useStorage()
  const storageUserDecathlon = storageGetItem('user-decathlon', 'session')

  const [userCpf, setUserCpf] = useState<string>()

  useEffect(() => {
    if (storageUserDecathlon && userCpf === undefined) {
      setUserCpf(
        JSON.parse(storageUserDecathlon).getUserProfile.additional_information
      )
    }
  }, [storageUserDecathlon, userCpf])

  return (
    <section className="cashback-banner relative ppp:px-2 pp:px-4 sm:px-6 md:px-12 ppp:pb-5 md:pb-8 bg-white">
      <div className="cashback-banner__image relative">
        <picture>
          <source
            srcSet="https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-mobile.png"
            media="(max-width: 766px)"
          />
          <source
            srcSet="https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-tablet.png"
            media="(max-width: 1022px)"
          />
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/main-banner-cashback-desktop.png"
            alt="Imagem do banner da página de Cashback"
            width="1344"
            height="360"
            className="w-full"
          />
        </picture>
        <div className="cashback-banner__image__text absolute ppp:bottom-5 left-0 sm:max-w-min sm:left-1/2 -translate-x-1/2 md:left-[21rem] md:bottom-10">
          <h1 className="cashback-banner__image__text--title text-white uppercase my-0 font-bold italic ppp:text-3xl pp:text-6xl text-center ppp:mb-3 md:text-left md:text-8xl">
            cashback
          </h1>
          <p className="cashback-banner__image__text--content text-white font-bold ppp:text-[14px] pp:text-sm ppp:leading-3 text-center ppp:mb-7 pp:px-6 sm:px-2 md:text-xl md:text-left md:w-[570px]">
            O Cashback é um novo benefício do Clube Decathlon que te dá dinheiro
            de volta para você utilizar em novas compras!
          </p>
          <Link
            to="https://www.decathlon.com.br/clube/"
            className="cashback-banner__image__text--button font-bold text-center rounded-[4px] bg-[#FFEA28] w-fit ppp:text-xs pp:text-base ppp:px-5 pp:px-6 ppp:py-3 block mx-auto md:text-base"
          >
            Confira os benefícios do Clube
          </Link>
        </div>
      </div>
      <div className="cashback-banner__saldo bg-white flex flex-col rounded-lg ppp:relative ppp:mx-auto ppp:max-w-[351px] ppp:p-3 ppp:gap-3 ppp:mt-9 md:w-[550px] md:max-w-[550px] md:absolute md:bottom-20 md:right-[148px] md:gap-6 md:p-12 shadow-[0_4px_24px_rgba(0,0,0,0.16)]">
        {cashBackBalance !== null ? (
          <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
            <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
              Saldo Cashback
            </h3>
            {cashBackBalance === 0 ? (
              <div className="flex ppp:gap-2 ppp:items-center">
                <span>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/finance_zero.png"
                    alt=""
                  />
                </span>
                <span className="pp:text-base md:text-2xl">R$ 0,00</span>
              </div>
            ) : (
              <div className="flex ppp:gap-2 ppp:items-center">
                <span>
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                    alt=""
                  />
                </span>
                <span className="pp:text-base md:text-2xl">
                  {formatterPrice(cashBackBalance)}
                </span>
              </div>
            )}
          </div>
        ) : (
          <>
            {userCpf === null && isLoggedIn ? (
              <>
                <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
                  <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
                    Saldo Cashback
                  </h3>
                  <div className="flex ppp:gap-2 ppp:items-center">
                    <span>
                      <img
                        src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                        alt=""
                      />
                    </span>
                    <span className="pp:text-base md:text-2xl">--</span>
                  </div>
                </div>
                <p className="ppp:text-[10px] pp:text-xs md:text-lg">
                  Cliente Clube? <br />
                  Preencha seu CPF
                  <Link to="/account" className="text-blue font-bold">
                    &nbsp;clicando aqui &nbsp;
                  </Link>
                  para consultar seu saldo.
                </p>
              </>
            ) : (
              <>
                <div className="flex ppp:flex-col ppp:gap-3 md:flex-row">
                  <h3 className="font-bold ppp:text-xs pp:text-base md:text-3xl md:max-w-[190px] ppp:text-[16px]">
                    Saldo Cashback
                  </h3>
                  <div className="flex ppp:gap-2 ppp:items-center">
                    <span>
                      <img
                        src="https://decathlonstore.vteximg.com.br/arquivos/finance.png"
                        alt=""
                      />
                    </span>
                    <span className="pp:text-base md:text-2xl">--</span>
                  </div>
                </div>
                <p className="pp:text-xs md:text-lg ppp:text-[12px]">
                  Cliente Clube?
                  <br />
                  <Link to="/account" className="text-blue font-bold">
                    Faça login
                  </Link>{' '}
                  para consultar seu saldo.
                </p>
              </>
            )}
          </>
        )}
      </div>
    </section>
  )
}

export default CashbackBanner
